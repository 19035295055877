import React, { useState, useEffect } from "react";
import Navbar from "../components/common/Navbar";
import HeroSlider from "../components/sliders/HeroSlider";
import sectiondata from "../store/store";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import GetInTouchButton from "../components/about/GetInTouchButton";
import know from "../assets/images/KnowAboutUs.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassArrowRight  } from '@fortawesome/free-solid-svg-icons';


const Disclaimer = ({ onAgree }) => (
  <div className="disclaimer-container">
    <p style={{textAlign:"justify"}}>
      The contents of this website are not directed towards residents of the
      United States of America or a resident of a jurisdiction, the laws of
      which impose prohibition on soliciting the advisory business in that
      jurisdiction without going through the registration requirements and/or
      prohibit the use of any information contained in this website.
    </p>
    <button onClick={onAgree}>Agree and Continue</button>
  </div>
);

export default function Home() {
  const [isAgreed, setIsAgreed] = useState(localStorage.getItem("isAgreed") === "true");
  const [isTableOpen, setIsTableOpen] = useState(true);

  useEffect(() => {
    const agreedStatus = localStorage.getItem("isAgreed") === "true";
    setIsAgreed(agreedStatus);
  }, []);

  const handleAgree = () => {
    localStorage.setItem("isAgreed", "true");
    setIsAgreed(true);
    // Show the table when agreed
    setIsTableOpen(true);
  };

  const handleCloseTable = () => {
    setIsTableOpen(false);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  return (
    <>
      {/* Display content only if agreed */}
      {isAgreed && (
        <>
          {/* Hero Slider */}
          <header className="header-area">
            <Navbar />
          </header>
          {/* Add the rest of your components here */}
          {/* ... other components ... */}
            {/* Other components */}
          <div className="get-in-touch-container">
            <GetInTouchButton />
          </div>

       <div className="know">
      <Link className="know-link"
        to={"/about"}
        style={{
          position: 'relative',
          borderRadius: '50%',
          width: '80px',
          height: '60px',
          textAlign: 'center',
          textDecoration: 'none',
          color: '#fff',
          overflow: 'hidden',
        }}
      >
          <FontAwesomeIcon icon={faMagnifyingGlassArrowRight }   className="know-icon" style={{ fontSize: '24px' ,position: 'relative',width: '30px',
          height: '30px',
      borderRadius: '50%',
      textAlign: 'center',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#C39239',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',}} />
      </Link>
      <span className="know-title">Know About Us</span>
    </div>


          {/* Table display */}
        {isTableOpen && (
          <div id="bottom-table">
            <i className="fa fa-times-circle" onClick={handleCloseTable}>&times;</i>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="5" className="text-center">Display of complaints data for advisory services-Aug'24</th>
                  </tr>
                  <tr>
                    <th>At the beginning of the month</th>
                    <th>Received during the month</th>
                    <th>Resolved during the month</th>
                    <th>Pending at the end of the month</th>
                    <th>Reasons for pendency</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          )}
        </>
      )}
        {/* <header className="header-area">
            <Navbar />
          </header> */}
      <HeroSlider slideItems={sectiondata.Sliders.heroSlider1} />
      {/* Disclaimer overlay */}
      {!isAgreed && <Disclaimer onAgree={handleAgree} />}
    </>
  );
}
