// import React from "react";
// import { FiMapPin, FiPhone, FiChevronRight } from "react-icons/fi";
// import { FaEnvelope } from "react-icons/fa";
// import sectiondata from "../../store/store";
// // import ComplaintsRedressalMatrix from "../../assets/jsons/ComplaintsRedressalMatrix.json";
// // import Table from "../../components/common/Table";

// export default function ContactSidebar() {
//   // const columns = React.useMemo(
//   //   () => [
//   //     {
//   //       Header: "Level",
//   //       accessor: "Level",
//   //     },
//   //     {
//   //       Header: "Name",
//   //       accessor: "name",
//   //     },
//   //     {
//   //       Header: "Details",
//   //       accessor: "contact",
//   //     },
//   //   ],
//   //   []
//   // );

//   return (
//     <aside className="contact-sidebar">
//       {sectiondata.contactdata.sidebar.title ? (
//         <h1>{sectiondata.contactdata.sidebar.title}</h1>
//       ) : (
//         ""
//       )}
//       {/* {sectiondata.contactdata.sidebar.img ? (
//         <img src={sectiondata.contactdata.sidebar.img} alt="Contact Sidebar" />
//       ) : (
//         ""
//       )} */}
//       {/* {sectiondata.contactdata.sidebar.content ? (
//         <p>{sectiondata.contactdata.sidebar.content}</p>
//       ) : (
//         ""
//       )} */}
//       <div className="contact-sidebar-infos">
//         {sectiondata.contactdata.sidebar.address ? (
//           <div className="item d-flex">
//             <div className="icon">
//             <a href="https://goo.gl/maps/5BnuaTJkYDC3tHvn6" target="_blank">
//               <span>
//                 <FiMapPin />
//               </span>
//               </a>
//             </div>
//             <div className="details">
//               <h3>Address</h3>
//               <span>
//                 Chandak Chambers,<br/> Executive Spaces,<br/> 7th Floor, Andheri East, Mumbai-400099<br/>
//                 <br/>
//                 No 360, 6th Main,<br/> 1st Block, Koramangala, Bengaluru-560034
//               </span>
//             </div>
//           </div>
//         ) : (
//           ""
//         )}

//         {sectiondata.contactdata.sidebar.phonenum ||
//         sectiondata.contactdata.sidebar.phonenum2 ? (
//           <div className="item d-flex">
//             <div className="icon">
//             <a href={'tel:'+sectiondata.HeaderData.HeaderBar.phoneNumber}>
//               <span>
//                 <FiPhone />
//               </span>
//               </a>
//             </div>
//             <div className="details">
//               <h3>Phone</h3>
//               {sectiondata.contactdata.sidebar.phonenum ? (
//                 <span>Land line: 080 4169 0158</span>
//               ) : (
//                 ""
//               )}
//               {sectiondata.contactdata.sidebar.phonenum2 ? (
//                 <span>Phone: +91 97314 55558</span>
//               ) : (
//                 ""
//               )}
//             </div>
//           </div>
//         ) : (
//           ""
//         )}

//         {sectiondata.contactdata.sidebar.email ||
//         sectiondata.contactdata.sidebar.email2 ? (
//           <div className="item d-flex">
//             <div className="icon">
//             <a href={"mailto:"+sectiondata.HeaderData.HeaderBar.email}>
//               <span>
//                 <FaEnvelope />
//               </span>
//          </a>
//             </div>
//             <div className="details">
//               <h3>Email</h3>
//               {sectiondata.contactdata.sidebar.email ? (
//                 <span>info@agreyacapital.com</span>
//               ) : (
//                 ""
//               )}
//               {/* {sectiondata.contactdata.sidebar.email2 ? (
//                 <span>{sectiondata.contactdata.sidebar.email2}</span>
//               ) : (
//                 ""
//               )} */}
//             </div>
//           </div>
//         ) : (
//           ""
//         )}
//       </div>

//       {/* {sectiondata.contactdata.sidebar.button ? (
//         <button className="theme-button" type="submit">
//           {sectiondata.contactdata.sidebar.button}{" "}
//           <FiChevronRight className="icon" />
//         </button>
//       ) : (
//         ""
//       )} */}
//       {/* <div style={{ marginTop: "15%",maxWidth: "700px", margin: "auto" }}>
//           <Table
//             title="Complaints Redressal Matrix"
//             columns={columns}
//             data={ComplaintsRedressalMatrix}
//           />
//         </div> */}
//     </aside>
//   );
// }





























import React from "react";
import { FiMapPin, FiPhone, FiChevronRight } from "react-icons/fi";
import { FaEnvelope } from "react-icons/fa";
import sectiondata from "../../store/store";

export default function ContactSidebar() {
  return (
    <aside className="contact-sidebar">
      {sectiondata.contactdata.sidebar.title ? (
        <h2 style={{marginBottom:"30px", textAlign: "center"}}>{sectiondata.contactdata.sidebar.title}</h2>
      ) : (
        ""
      )}
      <div className="contact-sidebar-infos">
        <div className="item d-flex">
          <div className="icon">
            <a href="https://goo.gl/maps/5BnuaTJkYDC3tHvn6" target="_blank">
              <span>
                <FiMapPin />
              </span>
            </a>
          </div>
          <div className="details">
            <th>Bengaluru Registered Office</th>
            <div>
            <td>
              Agreya Capital Advisors LLP<br />
              No 360, 6th Main, 1st Block<br />
              Koramangala, Bengaluru - 560034<br />
              <a className='names' href="tel:+918041690158">Landline: +91 (80) 4169 0158</a><br />
              {/* Landline: +91 (80) 4169 0158<br />
              Mobile: +91 97314 55558 */}
            </td>
            </div>
          </div>
        </div>

        <div className="item d-flex">
          <div className="icon">
            <a href="#" target="_blank">
              <span>
                <FiMapPin />
              </span>
            </a>
          </div>
          <div className="details">
            <th>Mumbai Branch Office</th>
            <div>
                <td>
              Chandak Chambers, Executive Spaces<br />
              7th Floor, Andheri East, Mumbai - 400099<br />
              <a className='names' href="tel:+919833013099">Mobile: +91 98330 13099</a>
                </td>
            </div>
          </div>
        </div>

        <div className="item d-flex">
          <div className="icon">
            <a href="#" target="_blank">
              <span>
                <FiMapPin />
              </span>
            </a>
          </div>
          <div className="details">
            <th>Noida Branch Office</th>
            <div>
                <td>
              E75, 1st Floor<br />
              Sector 63, Noida - 201301<br />
              <a className='names' href="tel:+919711003904">Mobile: +91 97110 03904</a>
                </td>
            </div>
          </div>
        </div>

        {/* {sectiondata.contactdata.sidebar.phonenum ||
        sectiondata.contactdata.sidebar.phonenum2 ? (
          <div className="item d-flex">
            <div className="icon">
              <a href={"tel:" + sectiondata.HeaderData.HeaderBar.phoneNumber}>
                <span>
                  <FiPhone />
                </span>
              </a>
            </div>
            <div className="details">
              <h3>Phone</h3>
              {sectiondata.contactdata.sidebar.phonenum ? (
                <span>Land line: 080 4169 0158</span>
              ) : (
                ""
              )}
              {sectiondata.contactdata.sidebar.phonenum2 ? (
                <span>Phone: +91 97314 55558</span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )} */}

        {/* {sectiondata.contactdata.sidebar.email ||
        sectiondata.contactdata.sidebar.email2 ? (
          <div className="item d-flex">
            <div className="icon">
              <a href={"mailto:" + sectiondata.HeaderData.HeaderBar.email}>
                <span>
                  <FaEnvelope />
                </span>
              </a>
            </div>
            <div className="details">
              <h3>Email</h3>
              {sectiondata.contactdata.sidebar.email ? (
                <span>info@agreyacapital.com</span>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </aside>
  );
}

